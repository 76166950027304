import axios from 'axios'
import {notify} from "../../components/Notify";
import {nodeFromString} from "../../tools/NodeFromString";
import bodyFloorSelect from "../../components/BodyFloorSelect";

export default function shopPavilions() {
    let container = document.querySelector('.shop-pavilions-container')

    if (container) {
        let list = container.querySelector('.shop-pavilions'),
            count = container.querySelectorAll('.shop-pavilion').length - 1

        function initItem(item) {
            item.querySelector('.shop-pavilion-remove')?.addEventListener('click', e => {
                e.preventDefault()
                item.remove()
            })
            bodyFloorSelect(item)

            item.querySelectorAll('select').forEach(select => {
                $(select).select2({
                    placeholder: $(select).placeholder,
                    allowClear: false
                });
            })
        }

        function initAdd() {
            container.querySelector('.shop-pavilions-add')?.addEventListener('click', e => {
                e.preventDefault()
                axios.get(`/admin/shops/pavilion?index=${++count}`)
                    .then(result => {
                        let item = nodeFromString(result.data.data)
                        initItem(item)
                        list.append(item)
                    })
                    .catch(error => {
                        notify('Error', 'danger', error)
                    })
            })
        }

        initAdd()
        container.querySelectorAll('.shop-pavilion').forEach(initItem)
    }
}

