import {notify} from "../components/Notify";

export default function requestErrorNotification(error) {
    if (error.response.status === 422) {
        let errors = error.response.data.errors;
        notify(error.response.data.message, 'danger', Object.values(errors)[0][0])
    } else {
        notify('Error', 'danger', error)
    }
}
