import ImageTool from "@editorjs/image";

export default class Image extends ImageTool {
    onUpload(response) {
        if (response.success && response.data) {
            this.image = response.data;
        } else {
            this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
        }
    }
}
