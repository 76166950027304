import axios from "axios";
import {notify} from "../../components/Notify";

export default function resolveButton(button, url) {
    button.addEventListener('click', () => {
        axios.post(url)
            .then(result => result.data.success && button.remove())
            .catch(e => notify('Error', 'danger', e.message))
    })
}
