export default function categoriesSelect(container) {
    function parseJSON(str) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return undefined;
        }
    }

    let categoriesSelect = container.querySelector('[js-parent-categories-select]'),
        subcategoriesSelect = container.querySelector('[js-child-categories-select]');

    if (categoriesSelect) {
        let $parent = $(categoriesSelect),
            children = parseJSON(categoriesSelect.dataset.childCategories);

        function getSubcategories(ids, defaultValue) {
            let options = []

            ids.forEach(id => {
                let childrenObj = children[id],
                    keys = Object.keys(childrenObj),
                    values = Object.values(childrenObj);

                values.forEach((value, index) => {
                    options.push(new Option(value, keys[index], defaultValue === keys[index], false))
                })
            })

            return options;
        }

        if (subcategoriesSelect && children !== undefined) {

            let $subcategories = $(subcategoriesSelect),
                subcategoriesDefaultValue = parseJSON(subcategoriesSelect.dataset.value) ?? []

            function hideSubcategories() {
                $subcategories.attr('disabled', 'disabled')
            }

            function showSubcategories() {
                $subcategories.removeAttr('disabled')
            }

            function processSubcategories(init = false) {
                let categories = $parent.val()
                $subcategories.empty().trigger('change')
                if (categories) {
                    getSubcategories(categories, subcategoriesDefaultValue).forEach(op => $subcategories.append(op))
                    showSubcategories()
                    $subcategories.trigger('change')
                    if (init) {
                        $subcategories.val(subcategoriesDefaultValue).trigger('change')
                    }
                } else {
                    hideSubcategories()
                }
            }

            $parent.on('change', processSubcategories);

            processSubcategories(true)
        }

    }
}

