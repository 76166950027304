import Select2 from "./Select2";
import Dropzone from "./Dropzone";
import SingleImageCropper from "./SingleImageCropper";

export default function arrayInput(container) {
    let list = container.querySelector('.array-inputs');

    function count() {
        return list.querySelectorAll('.array-input').length;
    }

    function initInput(input) {
        input.value = '';
        input.name = input.name.replace(/\[\d+]/, `[${count()}]`)
        input.id = input.name

        if (input.hasAttribute('dropzone')) {
            let next = input.nextElementSibling
            if (next.classList.contains('dropzone')) {
                next.remove();
            }
            input.removeAttribute('data-files')
            new Dropzone(input)
        }

        if (input.hasAttribute('single-image-cropper')) {
            let next = input.nextElementSibling
            if (next.classList.contains('crop')) {
                next.remove();
            }
            new SingleImageCropper(input)
        }
    }

    function initSelect(select) {
        select.value = '';
        select.name = select.name.replace(/\[\d+]/, `[${count()}]`)
        select.id = select.name

        Select2(select)
    }

    function initItem(item) {
        item.querySelector('.array-input-add').addEventListener('click', () => create())
        item.querySelector('.array-input-remove').addEventListener('click', () => item.remove())
    }

    function create() {
        let cloning = list.querySelector('.array-input'),
            selects = cloning.querySelectorAll('select');

        selects.forEach(select => {
            if (select.hasAttribute('data-select2-id')) {
                select.classList.add('select2-destroyed');
                $(select).select2('destroy')
            }
        })

        let item = cloning.cloneNode(true)
        item.querySelectorAll('input').forEach(input => initInput(input))
        item.querySelectorAll('select').forEach(select => initSelect(select))

        selects.forEach(select => {
            if (select.classList.contains('select2-destroyed')) {
                Select2(select)
                select.classList.remove('select2-destroyed')
            }
        })

        list.querySelectorAll('.array-input').forEach(item => {
            item.querySelector('.array-input-add').style.display = 'none';
            item.querySelector('.array-input-remove').style.display = '';
        })

        item.querySelector('.array-input-add').style.display = '';
        item.querySelector('.array-input-remove').style.display = 'none';


        initItem(item)
        list.append(item)
    }

    list.querySelectorAll('.array-input').forEach(initItem)
}
