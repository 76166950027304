export default function Select2(select) {
    let $select = $(select),
        $colors = $select.data('colors'),
        $images = $select.data('images'),
        tags = select.hasAttribute('data-tags')

    function getColor(id) {
        let colorKeys = typeof $colors === 'object' ? Object.keys($colors) : [],
            color = colorKeys.find(key => key == id)

        return color ? $colors[color] : null
    }

    function getImage(id) {
        let imageKeys = typeof $images === 'object' ? Object.keys($images) : [],
            image = imageKeys.find(key => key == id)

        return image ? $images[image] : null
    }

    $select.select2({
        placeholder: $select.placeholder,
        allowClear: false,
        templateResult: state => {
            if (!state.id) {
                return state.text;
            }

            let color = getColor(state.id),
                image = getImage(state.id)

            if (image) {
                return $(`<span><img src="${image}" class="img-flag">${state.text}</span>`)
            }

            if (color) {
                return $(`<span><div style="background-color: ${color}; display: inline-block; width: 20px; height: 13px; margin-right: 10px"></div>${state.text}</span>`)
            }

            return state.text;
        }
    });
}
