export default function bodyFloorSelect(container) {
    function parseJSON(str) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return undefined;
        }
    }

    let bodySelect = container.querySelector('[js-body-select]'),
        floorSelect = container.querySelector('[js-floor-select]'),
        pavilionSelect = container.querySelector('[js-pavilion-select]');

    function selectHasValue(select, value) {
        return $(select).find("option[value='" + value + "']").length !== 0;
    }

    if (bodySelect) {
        let $body = $(bodySelect),
            floors = parseJSON(bodySelect.dataset.floors);


        function getFloors(id, defaultValue) {
            if (id) {
                let floorsObj = floors[id],
                    keys = Object.keys(floorsObj),
                    values = Object.values(floorsObj);

                return values.map((value, index) => {
                    return new Option(value, keys[index], defaultValue === keys[index], false)
                })

            }
            return [];
        }

        if (floorSelect && floors !== undefined) {


            let $floor = $(floorSelect),
                floorDefaultValue = floorSelect.dataset.value,
                pavilions = parseJSON(floorSelect.dataset.pavilions)

            function getPavilions(id) {
                if (id) {
                    let pavilionsObj = pavilions[id],
                        keys = Object.keys(pavilionsObj),
                        values = Object.values(pavilionsObj);

                    return values.map((value, index) => new Option(value, keys[index], false, false))

                }
                return [];
            }

            function hideFloors() {
                $floor.attr('disabled', 'disabled')
            }

            function showFloors() {
                $floor.removeAttr('disabled')
            }

            function processFloors() {
                let bodyId = $body.val()
                $floor.empty().trigger('change')
                if (bodyId) {
                    getFloors(bodyId, floorDefaultValue).forEach(op => $floor.append(op))
                    showFloors()
                    let floorValue = selectHasValue($floor, floorDefaultValue) ? floorDefaultValue : '';
                    $floor.val(floorValue).trigger('change')
                } else {
                    hideFloors()
                }
            }

            $body.on('change', processFloors);

            processFloors()

            if (pavilionSelect && pavilions !== undefined) {
                let $pavilion = $(pavilionSelect),
                    pavilionDefaultValue = pavilionSelect.dataset.value;

                function hidePavilions() {
                    $pavilion.attr('disabled', 'disabled')
                }

                function showPavilions() {
                    $pavilion.removeAttr('disabled')
                }

                function processPavilions() {
                    let floorId = $floor.val()
                    $pavilion.empty().trigger('change')
                    if (floorId) {
                        getPavilions(floorId).forEach(op => $pavilion.append(op))
                        showPavilions()
                        let pavilionValue = selectHasValue($pavilion, pavilionDefaultValue) ? pavilionDefaultValue : '';
                        $pavilion.val(pavilionValue).trigger('change')
                    } else {
                        hidePavilions()
                    }
                }

                $floor.on('change', processPavilions);

                processPavilions()
            }

        }

    }
}

