import Sortable from "sortablejs";

export default function sortableContainer(container) {
    let options = {
        sort: true,
        animation: 300,
        onChange: () => updateIndexes(),
    };

    if (container.getAttribute('sortable-handle')) {
        options.handle = container.getAttribute('sortable-handle');
    }
    Sortable.create(container, options)

    function updateIndexes() {
        container.children.forEach((child, index) => {
            let indexHandler = child.querySelector('[sortable-index-handler]')
            if (indexHandler) {
                indexHandler.value = index
            }
        })
    }
}
