import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import Quote from "@editorjs/quote";
import List from "@editorjs/list";
import Embed from "@editorjs/embed";
import {CsrfToken} from "../tools/CsrfToken";
import Image from "../editor/Image";
import Table from "@editorjs/table";
import File from "../editor/File";

export default class Editor {
    constructor(input) {
        this.input = input

        this.init()
    }

    init() {
        this.editorJs = new EditorJS({
            holder: this.getArea(),
            data: this.inputValue,
            placeholder: this.input.placeholder ?? '',
            tools: {
                header: {
                    class: Header,
                    shortcut: 'CMD+H',
                    inlineToolbar: true,
                    config: {
                        defaultLevel: 3,
                        levels: [3, 4, 5],
                    },
                },
                paragraph: {
                    class: Paragraph,
                    shortcut: 'CMD+P',
                    inlineToolbar: true,
                },
                quote: {
                    class: Quote,
                    shortcut: 'CMD+Q',
                    inlineToolbar: true,
                },
                list: {
                    class: List,
                    shortcut: 'CMD+L',
                    inlineToolbar: true,
                },
                image: {
                    class: Image,
                    shortcut: 'CMD+I',
                    config: {
                        endpoints: {
                            byFile: '/admin/tools/images'
                        },
                        additionalRequestData: {
                            _token: CsrfToken,
                        }
                    },
                },
                embed: {
                    class: Embed,
                    config: {
                        services: {
                            youtube: true
                        }
                    }
                },
                file: {
                    class: File,
                    config: {
                        endpoint: '/admin/tools/files',
                        additionalRequestData: {
                            _token: CsrfToken,
                        }
                    }
                },
                table: {
                    class: Table,
                    inlineToolbar: true,
                    config: {
                        rows: 2,
                        cols: 3,
                    },
                },
            },
            onChange: () => this.save()
        });

        this.input.closest('form')?.addEventListener('submit', () => this.save())
    }

    get inputValue() {
        try {
            return JSON.parse(this.input.value)
        } catch (Error) {
            return null
        }
    }

    set inputValue(value) {
        this.input.value = JSON.stringify(value)
        this.input.dispatchEvent(new Event('change'))
    }

    getArea() {
        let element = document.createElement('div');
        this.input.after(element)

        return element
    }

    save() {
        this.editorJs.save().then(data => {
            this.inputValue = data
        })
    }
}

