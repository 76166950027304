import phoneMask from "../../components/PhoneMask";

export default function shopPhones() {
    let container = document.querySelector('.shop-phones-container');

    if (container) {
        let list = container.querySelector('.shop-phones');

        function initPhone(phone) {
            phone.querySelector('.shop-phone-add').addEventListener('click', () => create())
            phone.querySelector('.shop-phone-remove').addEventListener('click', () => phone.remove())
        }

        function create() {
            let phone = list.querySelector('.shop-phone').cloneNode(true)
            phone.querySelector('input').value = ''
            phoneMask(phone.querySelector('input'))

            list.querySelectorAll('.shop-phone').forEach(phone => {
                phone.querySelector('.shop-phone-add').style.display = 'none';
                phone.querySelector('.shop-phone-remove').style.display = '';
            })

            phone.querySelector('.shop-phone-add').style.display = '';
            phone.querySelector('.shop-phone-remove').style.display = 'none';


            initPhone(phone)
            list.append(phone)
        }

        list.querySelectorAll('.shop-phone').forEach(initPhone)
    }
}
