import Sortable from 'sortablejs';
import axios from "axios";

export default function sortingTable(container) {
    let list = container.querySelector('table tbody'),
        url = container.dataset.url;

    if (list) {
        function getElementByIndex(index) {
            let elements = [...list.querySelectorAll('[data-key]')]
            return elements[index] ?? null;
        }

        Sortable.create(list, {
            animation: 300,
            onEnd: event => submit(event.oldIndex, event.newIndex)
        })

        function submit(from, to) {
            if (from === to) {
                return true;
            }

            let dragged = getElementByIndex(to), after = getElementByIndex(to - 1),
                draggedKey = dragged.dataset.key, afterKey = after?.dataset.key

            axios.post(`${url}/${draggedKey}/move`, {after: afterKey})
                .then(result => true)
                .catch(error => false)
        }
    }
}
