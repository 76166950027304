import './components/Preloader'
import './components/Notify'
import './components/Tabs'
import './components/ImageCropper'
import './components/ImageSaver'
import datetimePicker from "./components/DatetimePicker";

import SingleImageCropper from './components/SingleImageCropper'
import SingleImageSaver from "./components/SingleImageSaver";
import Gallery from "./components/Gallery";
import Editor from "./components/Editor";
import Dropzone from "./components/Dropzone";
import phoneMask from "./components/PhoneMask";
import bodyFloorSelect from "./components/BodyFloorSelect";
import shopPavilions from "./pages/shops/pavilions";
import shopPhones from "./pages/shops/phones";
import categoriesSelect from "./components/CategoriesSelect";
import sortableContainer from "./components/SortableContainer";
import destroyButton from "./pages/callbacks/destroyButton";
import resolveButton from "./pages/callbacks/resolveButton";
import Select2 from "./components/Select2";
import arrayInput from "./components/ArrayInput";
import sortingTable from "./components/SortingTable";


document.querySelectorAll('[single-image-saver]').forEach(input => {
    new SingleImageSaver(input)
})
document.querySelectorAll('[single-image-cropper]').forEach(input => {
    new SingleImageCropper(input)
})
document.querySelectorAll('[gallery]').forEach(input => {
    new Gallery(input)
})
document.querySelectorAll('[editor]').forEach(input => {
    new Editor(input)
})
document.querySelectorAll('[dropzone]').forEach(input => {
    new Dropzone(input)
})
document.querySelectorAll('[phone-mask]').forEach(input => {
    phoneMask(input)
})
document.querySelectorAll('[datetime-picker]').forEach(input => {
    datetimePicker(input)
})
document.querySelectorAll('[body-floor-select]').forEach(container => {
    bodyFloorSelect(container)
})

document.querySelectorAll('[categories-select]').forEach(container => {
    categoriesSelect(container)
})

document.querySelectorAll('[sortable-container]').forEach(container => {
    sortableContainer(container)
})

document.querySelectorAll('[destroy-callback-btn]').forEach(button => {
    destroyButton(button, button.getAttribute('destroy-callback-btn'))
})

document.querySelectorAll('[resolve-callback-btn]').forEach(button => {
    resolveButton(button, button.getAttribute('resolve-callback-btn'))
})

document.addEventListener('DOMContentLoaded', () => {
    shopPavilions()
    shopPhones()

    document.querySelectorAll('[data-toggle="select"]').forEach(select => {
        Select2(select)
    })
    document.querySelectorAll('[array-input]').forEach(container => {
        arrayInput(container)
    })
    document.querySelectorAll('[sorting-table]').forEach(container => {
        sortingTable(container)
    })
})
