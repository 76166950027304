import {notify} from "../../components/Notify";
import axios from "axios";

export default function destroyButton(button, url) {
    button.addEventListener('click', () => {
        axios.delete(url)
            .then(result => result.data.success && button.closest('.card').remove())
            .catch(e => notify('Error', 'danger', e.message))
    })
}
